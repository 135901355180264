* {
  background-color: transparent;
}

a {
  color: black;
  cursor: pointer;
  font-family: "JSL-blackletter";
  font-size: 5vw;
  text-decoration: none;
}
a:hover {
  color: white;
  text-shadow: 1px 1px 1px black;
}
li {
  margin: 0 min(3vw, 3.33em);
}
ul {
  display: inline-flex;
  justify-content: center;
  list-style-type: none;
  margin-top: 1.5em;
  min-width: 15%;
  padding: 0;
  z-index: 1;
}
@media
(prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}
