.rhap_container {
    background: #f7f7f9;
  }
  
  .rhap_progress-section {
    height: 20px;
    padding-bottom: 20px;
  }
  
  .rhap_main-controls-button {
    width: 80px !important;
    height: 80px !important;
  }
  
  .rhap_main-controls-button {
    width: 56px;
    height: 56px;
    display: block;
  }
  
  .rhap_main-controls-button svg {
    color: #ff5555;
    width: 100%;
    height: 100%;
  }
  .rhap_progress-filled,
  .rhap_progress-indicator {
    background-color: #ff5555 !important;
  }
  
  .rhap_button-clear.rhap_volume-button {
    color: #ff5555 !important;
  }
  
  .rhap_volume-bar, .rhap_volume-indicator {
    background-color: red;
  }