.About {
    height: 100vh;
    padding-top: 3em;
}
.Box{
    display: block;
    float: left;
    font-family: 'Warsuck';
    letter-spacing: .0666vw;
    margin: 0 auto;
    max-width: 17em;
}
.Box:hover {
    cursor: default;
    filter: invert(55.55%);
}
.Container {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: 66%;
}
.Desc {
    filter: invert(19%);
    height: 1.35em;
    margin-top: -.33em;
}
.Who {
    filter: invert(32.23%);
    height: 4em;
    margin: 2em;
}

p {
    font-size: min(1.11em, 1.75vw);
}