.App {
  background: rgb(0,0,0);
  background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(64,64,64,1) 99%);
  background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(64,64,64,1) 99%);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(64,64,64,1) 99%);
  background-repeat: no-repeat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
}

.Band-name {
  letter-spacing: 4vw;
  margin: 0;
}

#banner {
  font-size: 6.66vw;
  padding-top: 1.25em;
}

.Header {
  display: inline-block;
  font-family: 'JSL-blackletter';
  overflow: hidden;
  vertical-align: top;
  width: 100vw;
}

.Nav-container {
  background-color: transparent;
  display: block;
  position: sticky;
  margin-bottom: 10em;
  top: 0;
}

.rap-container {
  width: 666px;
}

#about, #media {
  height: 100vh;
}

#audio-selection {
  height: 4em;
}

#audio-selection > * {
  display: block;
}

#audio-selection:hover > * {
  color: white;
}

#footer {
  font-family: 'Warsuck';
  font-size: small;
  font-weight: 300;
  letter-spacing: .123vw;
  line-height: 1.75em;
  min-height: 3rem;
  padding-bottom: 3em;
  margin-top: -5em;
  text-shadow: .1px .1px .1px #333;
  left: 0;
  width: 100%;
  text-align: center;
}

#footer:hover {
  bottom: .5em;
  color: white;
  cursor: default;
  font-size: small;
  font-weight: 300;
  text-shadow: .5px .5px .5px black;
}

#media {
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  font-family: 'Warsuck';
  letter-spacing: .555vw;
  margin-top: 5.5em;
  padding-top: 6.66em;
  row-gap: 1em;
}

#player-wrapper {
  position: relative;
  z-index: 1;
}

#selection-title:hover {
  color: white;
} 

#selection-title {
  color: black;
  cursor: crosshair;
  margin-bottom: 0;
  text-shadow: .1px .1px .1px white;
}

.rhap_container {
  background: inherit;
  margin-top: -.432em;
}

.rhap_progress-section {
  display: none;
}

.rhap_main-controls-button {
  border-style: none;
  color: #999;
  font-size: x-large;
  margin: .5em;
}


.rhap_button-clear.rhap_volume-button {
  display: none;
}

.rhap_volume-bar, .rhap_volume-indicator {
  background-color: grey;
}
@font-face {
  font-family: 'JSL-blackletter';
  src: local('JSL-blackletter'), url(./assets/fonts/JBLACK.TTF) format('truetype');
}
@font-face {
  font-family: 'Warsuck';
  src: local('Warsuck'), url(./assets/fonts/Warsuck.ttf) format('truetype');
}

